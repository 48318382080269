/* SCSS Document */
/***************
GOOGLE FONT
*****************/
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@font-face {
  font-family: "Better Saturday";
  src: url("../../font/Better-Saturday.otf");
}
/**************  
VARIABLES
***************/
html {
  background: #000;
}
@media only screen and (min-width: 641px) {
  html {
    background: url("/background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-position: left center;
  }
}

h2, h3, h4, h5, h6 {
  font-family: "Source Sans 3", sans-serif;
  font-weight: bold;
}

h1 {
  font-family: "Better Saturday";
}

body, p, li, a {
  font-family: "Source Sans 3", sans-serif;
}

#result-text.invalid {
  color: red;
  font-family: "Source Sans 3", sans-serif;
  font-size: 1.4rem;
}